import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
  OrganizationSortField,
  SetOrganizationSettingsCommand,
} from '@sherweb/core/openapi-generated/index.defs'
import { OrganizationSettingsService } from '@sherweb/core/openapi-generated/OrganizationSettingsService'
import { ResellerCacheService } from '@sherweb/core/openapi-generated/ResellerCacheService'

import { Pagination } from '@sherweb/core/components/DataTable/types'
import { errorNotification, successNotification } from '@sherweb/core/components/ToastNotifications'
import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'
import { missingParametersError } from '@sherweb/core/utils/error'

import { useSelectedResellerId } from '@ssp/modules/reseller'

import { buildDetailedOrganization } from './organizations.builder'
import { getOrganizationsPaginationDefaultValues } from './organizations.helpers'
import {
  detailedOrganization,
  detailedOrganizationsList,
  detailedOrganizationsListQueryPrefix,
  detailedOrganizationsWithInfiniteScroll,
} from './organizations.queries'

export const useGetOrganizationsQuery = (
  queryOptions: Pagination<OrganizationSortField> = getOrganizationsPaginationDefaultValues()
) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedResellerId = useSelectedResellerId()

  return useQuery({
    queryKey: detailedOrganizationsList.queryKey(selectedResellerId, queryOptions),
    keepPreviousData: true,
    queryFn: async () => await detailedOrganizationsList.queryFn(selectedResellerId, queryOptions),
    enabled: isLoggedIn && !!selectedResellerId,
    staleTime: detailedOrganizationsList.staleTime,
  })
}

export const useGetInfiniteScrollOrganizationsQuery = (
  queryOptions: Pagination<OrganizationSortField>
) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedResellerId = useSelectedResellerId()

  return useInfiniteQuery({
    queryKey: detailedOrganizationsWithInfiniteScroll.queryKey(selectedResellerId, queryOptions),
    queryFn: async ({ pageParam = 1 }) =>
      await detailedOrganizationsWithInfiniteScroll.queryFn(selectedResellerId, {
        ...queryOptions,
        page: pageParam,
      }),
    enabled: isLoggedIn && !!selectedResellerId,
    staleTime: detailedOrganizationsWithInfiniteScroll.staleTime,
    getNextPageParam: lastPage => (lastPage?.hasNextPage ? lastPage?.pageIndex + 1 : null),
  })
}

export const useGetDetailedOrganizationQuery = (organizationId?: string) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()
  return useQuery({
    queryKey: detailedOrganization.queryKey(organizationId),
    queryFn: async () => await detailedOrganization.queryFn(organizationId),
    enabled: isLoggedIn && !!organizationId,
    select: data => buildDetailedOrganization(data),
    staleTime: detailedOrganization.staleTime,
  })
}

export const useRefreshOrganizationsMutation = () => {
  const { t } = useTranslation()

  const resellerId = useSelectedResellerId()

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      if (!resellerId) {
        throw missingParametersError()
      }

      return await ResellerCacheService.refreshResellerCache({
        resellerId,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [detailedOrganizationsListQueryPrefix],
      })

      successNotification(t('rsp:pages.organizations.refresh.successMessage'))
    },
    onError: () => {
      errorNotification(t('rsp:pages.organizations.refresh.failureMessage'))
    },
  })
}

export const useSetOrganizationSettings = (organizationId?: string) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: { command: SetOrganizationSettingsCommand }) => {
      if (!organizationId || !data.command) {
        throw missingParametersError()
      }

      return await OrganizationSettingsService.setOrganizationSettings({
        organizationId,
        ...data,
      })
    },
    onSuccess: async () => {
      successNotification(t('rsp:pages.settings.labels.changesApplied'))

      await queryClient.invalidateQueries({
        queryKey: detailedOrganization.queryKey(organizationId),
      })

      await queryClient.invalidateQueries({
        queryKey: [detailedOrganizationsListQueryPrefix],
      })
    },
  })
}
