import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { CatalogListQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { useSelectedResellerId } from '@rsp/modules/reseller'

import { availableCatalogs } from './marketplace.queries'

export const useGetAvailableCatalogsQuery = <TResult = CatalogListQueryResult[]>(
  options?: Omit<UseQueryOptions<CatalogListQueryResult[], Error, TResult>, 'queryKey'>
) => {
  const resellerId = useSelectedResellerId()

  return useQuery<CatalogListQueryResult[], Error, TResult>({
    queryKey: availableCatalogs.queryKey(resellerId),
    queryFn: async () => await availableCatalogs.queryFn(resellerId),
    enabled: !!resellerId,
    staleTime: availableCatalogs.staleTime,
    ...options,
  })
}
