import { useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import Card from '@sherweb/core/components/Card'
import { DataTableMobileHeader } from '@sherweb/core/components/DataTable/DataTableInputMobile'
import { useSetTableSearchParams } from '@sherweb/core/components/DataTable/hooks/useSetTableSearchParam'
import { NoResultsMobile } from '@sherweb/core/components/NoResults/NoResultsMobile'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import { Order } from '@sherweb/core/modules/orders/core/orders.model'
import { OrderStatus } from '@sherweb/core/modules/orders/ui/OrderStatus'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { useGetOrdersQuery } from '@rsp/modules/orders'

import { OrderActions } from './OrderActions'

const OrdersTableMobile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatDate = useDateFormatter(language, dateFormatTypes.datetime)

  const ordersQuery = useGetOrdersQuery()

  const formatMoneyAmount = useMoneyFormatter(language)

  const { search: searchText, onSetSearchParams } = useSetTableSearchParams()

  const filteredData = ordersQuery?.data?.filter((item: Order) => {
    return (
      (item.humanReadableId ?? '')?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.organizationName?.toLowerCase().includes(searchText.toLowerCase())
    )
  })

  const renderContent = () => {
    return filteredData?.length === 0 ? (
      <NoResultsMobile>{t('rsp:pages.orders.noOrders')}</NoResultsMobile>
    ) : (
      filteredData?.map(order => (
        <Accordion key={order.id} type="single" collapsible>
          <AccordionItem value={order.id}>
            <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
              {order.organizationName} - {order.humanReadableId}
              <AccordionTriggerSubContent>
                <OrderStatus orderStatus={order?.orderStatus} />
              </AccordionTriggerSubContent>
            </AccordionTrigger>
            <AccordionContent>
              <AccordionContentList>
                <AccordionContentListItem
                  label={t('rsp:pages.orders.list.organizationName')}
                  value={order?.organizationName}
                />
                <AccordionContentListItem
                  label={t('rsp:pages.orders.list.createdOn')}
                  value={formatDate(order?.createdOn)}
                />
                <AccordionContentListItem
                  label={t('rsp:pages.orders.list.orderValue')}
                  value={order.orderValue ? formatMoneyAmount(order.orderValue) : null}
                />
                <AccordionContentListItem
                  label={t('rsp:pages.orders.list.status')}
                  value={<OrderStatus orderStatus={order.orderStatus} />}
                />
                <AccordionContentListItem
                  className="justify-end"
                  labelClassName="flex flex-wrap"
                  label={<OrderActions order={order} className="flex flex-wrap" />}
                />
              </AccordionContentList>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ))
    )
  }

  return (
    <Card padded>
      <div className="flex flex-col gap-4">
        <DataTableMobileHeader>
          <DataTableMobileHeader.Input
            placeholder={t('rsp:pages.orders.list.filterPlaceholder')}
            filteredText={searchText}
            setFilteredText={onSetSearchParams}
          />
        </DataTableMobileHeader>
        {isRequestLoading(ordersQuery) ? <SkeletonTable /> : renderContent()}
      </div>
    </Card>
  )
}

export default OrdersTableMobile
