import { Loader2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'

import { useRefreshOrganizationsMutation } from '@rsp/modules/organizations'

export const RefreshOrganizationsButton = () => {
  const { t } = useTranslation()

  const refreshOrganizationsMutation = useRefreshOrganizationsMutation()

  const handleRefreshOrganizations = () => {
    refreshOrganizationsMutation.mutate()
  }

  return (
    <Button
      variant="primary"
      size="sm"
      className="h-full"
      data-testid="btnRefreshOrganizations"
      onClick={handleRefreshOrganizations}
      disabled={refreshOrganizationsMutation.isLoading}
    >
      {refreshOrganizationsMutation?.isLoading ? (
        <>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" data-testid="loaderRefreshOrganizations" />
          {t('rsp:pages.organizations.refresh.loading')}
        </>
      ) : (
        t('rsp:pages.organizations.refresh.title')
      )}
    </Button>
  )
}
