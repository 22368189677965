import { OrganizationOrdersService } from '@sherweb/core/openapi-generated/OrganizationOrdersService'

import { DEFAULT_STALE_TIME } from '@sherweb/core/utils/const'
import { rejectMissingParameters } from '@sherweb/core/utils/error/error'

export const ordersByOrganizationId = {
  queryKey: (organizationId?: string) => [
    'OrganizationOrdersService/getOrganizationOrdersById',
    organizationId,
  ],
  queryFn: async (organizationId?: string) => {
    if (!organizationId) {
      return await rejectMissingParameters()
    }

    return await OrganizationOrdersService.getOrganizationOrdersById({ organizationId })
  },
}

export const organizationOrderDetailsById = {
  queryKey: (organizationId?: string, orderId?: string) => [
    'OrganizationOrdersService/getOrganizationOrderDetailsById',
    organizationId,
    orderId,
  ],
  queryFn: async (organizationId?: string, orderId?: string) => {
    if (!organizationId || !orderId) {
      return await rejectMissingParameters()
    }

    return await OrganizationOrdersService.getOrganizationOrderDetailsById({
      orderId,
      organizationId,
    })
  },
  staleTime: DEFAULT_STALE_TIME,
}
