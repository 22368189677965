import { useQuery } from '@tanstack/react-query'

import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'
import { buildOrder } from '@sherweb/core/modules/orders/core/orders.builder'

import { useSelectedOrganization } from '@ssp/modules/organization'

import { ordersByOrganizationId, organizationOrderDetailsById } from './orders.queries'

export const useOrdersQuery = () => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedOrganization = useSelectedOrganization()

  const organizationId = selectedOrganization?.id

  return useQuery({
    queryKey: ordersByOrganizationId.queryKey(organizationId),
    queryFn: async () => await ordersByOrganizationId.queryFn(organizationId),
    enabled: isLoggedIn && !!organizationId,
  })
}

export const useGetOrderQuery = (orderId?: string) => {
  const selectedOrganization = useSelectedOrganization()

  const organizationId = selectedOrganization?.id

  return useQuery({
    queryKey: organizationOrderDetailsById.queryKey(organizationId, orderId),
    queryFn: async () => await organizationOrderDetailsById.queryFn(organizationId, orderId),
    enabled: !!organizationId && !!orderId,
    staleTime: organizationOrderDetailsById.staleTime,
    select: data => buildOrder(data),
  })
}
