import { useTranslation } from 'react-i18next'

import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import { Permission } from '@sherweb/core/modules/authorization'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'

import { protectPage } from '@rsp/app/ProtectedPage'
import ContainedPage from '@rsp/components/layout/ContainedPage'
import { useGetAvailableCatalogsQuery } from '@rsp/modules/martkeplace'

import { CatalogCard } from './components/CatalogCard'
import { MarketplaceLoader } from './MarketplaceLoader'

const MarketplacePage = () => {
  const { t } = useTranslation()

  const catalogsQuery = useGetAvailableCatalogsQuery()

  return (
    <ContainedPage>
      <div className="flex flex-col gap-12 md:gap-16">
        <PageTitleWrapper
          title={
            <PageTitle data-testid="pageMarketplaceTitle">
              {t('rsp:pages.marketplace.title')}
            </PageTitle>
          }
          subTitle={<PageSubTitle>{t('rsp:pages.marketplace.subtitle')}</PageSubTitle>}
        />

        <div className="mt-4 grid grid-cols-1 gap-4 lg:gap-6 xl:grid-cols-1">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2  lg:grid-cols-3 lg:gap-6 xl:grid-cols-4">
            {isRequestLoading(catalogsQuery) ? (
              <MarketplaceLoader />
            ) : (
              catalogsQuery?.data?.map(catalog => (
                <CatalogCard key={catalog.id} catalog={catalog} />
              ))
            )}
          </div>
        </div>
      </div>
    </ContainedPage>
  )
}

export default protectPage(MarketplacePage, Permission.CatalogManagementList)
