import { BuildingOffice2Icon, ListBulletIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { CatalogListQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import Card, { CardTitle } from '@sherweb/core/components/Card'
import { Typography } from '@sherweb/core/components/Typography'

type CatalogCardProps = {
  catalog: CatalogListQueryResult
}

export const CatalogCard = ({ catalog }: CatalogCardProps) => {
  const { t } = useTranslation()

  return (
    <Card className="flex h-40 flex-col justify-between md:p-4" key={catalog.id}>
      <div className="flex flex-col">
        <CardTitle>
          <Typography variant="heading6" weight="bold">
            {catalog.name}
          </Typography>
        </CardTitle>
        <Typography variant="label" colors="light">
          {catalog.description}
        </Typography>
      </div>
      <div className="mt-4">
        <div className="flex justify-start">
          <div className="flex items-center gap-1">
            <BuildingOffice2Icon className="h-4 w-4 shrink-0 opacity-50 dark:text-white" />
            <Typography variant="label" colors="light">
              {t('rsp:pages.marketplace.labels.organizations')} - {catalog.numberOrganizations}
            </Typography>
          </div>
          <div className="ml-4 flex items-center gap-1">
            <ListBulletIcon className="h-4 w-4 shrink-0 opacity-50 dark:text-white" />
            <Typography variant="label" colors="light">
              {t('rsp:pages.marketplace.labels.skus')} - {catalog.numberSkus}
            </Typography>
          </div>
        </div>
      </div>
    </Card>
  )
}
