import { ArrowTopRightOnSquareIcon, Cog6ToothIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { DetailedOrganizationListResult } from '@sherweb/core/openapi-generated/index.defs'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import Button, { Variant } from '@sherweb/core/components/Button'
import Card from '@sherweb/core/components/Card'
import { DataTableMobileHeader } from '@sherweb/core/components/DataTable/DataTableInputMobile'
import { useSetTableSearchParams } from '@sherweb/core/components/DataTable/hooks/useSetTableSearchParam'
import { InfiniteScroll } from '@sherweb/core/components/InfiniteScroll'
import { NoResultsMobile } from '@sherweb/core/components/NoResults/NoResultsMobile'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import {
  getAllPagesResults,
  isInfiniteScrollRequestHavingNoResults,
  isRequestLoading,
} from '@sherweb/core/modules/reactQuery'
import { DEFAULT_SERVER_DEBOUNCE_MS } from '@sherweb/core/utils/const'

import Routes from '@rsp/app/Routes'
import { useGetInfiniteScrollOrganizationsQuery } from '@rsp/modules/organizations'
import { getOrganizationsPaginationDefaultValues } from '@rsp/modules/organizations/core/organizations.helpers'

import Link from '@ssp/components/Link'

import { GoToOrganizationLink } from '../components/GoToOrganizationLink'
import { RefreshOrganizationsButton } from '../components/RefreshOrganizationsButton'
import { useRenderSettingsType, useRenderStatusBadge } from '../organizations.helper'

const OrganizationsTableMobile = () => {
  const { t } = useTranslation()

  const { search: searchText, onSetSearchParams } = useSetTableSearchParams()

  const organizationsQuery = useGetInfiniteScrollOrganizationsQuery(
    getOrganizationsPaginationDefaultValues({
      searchTerm: searchText,
    })
  )

  const noResults =
    isInfiniteScrollRequestHavingNoResults<DetailedOrganizationListResult>(organizationsQuery)

  const organizations = getAllPagesResults<DetailedOrganizationListResult>(organizationsQuery)

  const handleFetchNextPage = async () => {
    await organizationsQuery.fetchNextPage()
  }

  const renderSettingsType = useRenderSettingsType()

  const renderStatusBadge = useRenderStatusBadge()

  const renderContent = () => {
    return noResults ? (
      <NoResultsMobile>{t('rsp:pages.organizations.noOrganization')}</NoResultsMobile>
    ) : (
      <>
        {organizations?.map(organization => {
          if (!organization) {
            return null
          }

          return (
            <Accordion key={organization.organizationId} type="single" collapsible>
              <AccordionItem value={organization.organizationId}>
                <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                  {organization.organizationName}
                  <AccordionTriggerSubContent>
                    {renderStatusBadge(organization.isSuspended)}
                  </AccordionTriggerSubContent>
                </AccordionTrigger>
                <AccordionContent>
                  <AccordionContentList>
                    <AccordionContentListItem
                      label={t('rsp:pages.organizations.list.users')}
                      value={organization.userCount}
                    />
                    <AccordionContentListItem
                      label={t('rsp:pages.organizations.list.settings')}
                      value={renderSettingsType(organization.usesDefaultSettings)}
                    />
                    <AccordionContentListItem
                      label={t('rsp:pages.organizations.list.status')}
                      value={renderStatusBadge(organization.isSuspended)}
                    />
                    <AccordionContentListItem
                      label=""
                      value={
                        <div
                          className="flex flex-col gap-4"
                          data-testid="actionsOrganizationsMobile"
                        >
                          <Button
                            asChild
                            variant={Variant.Outline}
                            size="sm"
                            data-testid="btnViewSettings"
                          >
                            <Link
                              to={Routes.Organization}
                              params={{ organizationId: organization.organizationId ?? '' }}
                            >
                              <Cog6ToothIcon className="mr-1 h-5 w-5  hover:bg-slate-50" />
                              {t('rsp:pages.organizations.list.actions.viewSettingsMobile')}
                            </Link>
                          </Button>
                          <Button asChild variant={Variant.Outline} size="sm">
                            <GoToOrganizationLink
                              dataTestId="btnOpenOrganization"
                              organizationUniqueName={organization?.uniqueName}
                            >
                              <>
                                <ArrowTopRightOnSquareIcon className="mr-1 h-5 w-5  hover:bg-slate-50" />
                                {t('rsp:pages.organizations.list.actions.openOrganizationMobile')}
                              </>
                            </GoToOrganizationLink>
                          </Button>
                        </div>
                      }
                    />
                  </AccordionContentList>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          )
        })}
        {organizationsQuery?.hasNextPage ? (
          <InfiniteScroll
            isLoading={organizationsQuery.isFetchingNextPage}
            hasMore={organizationsQuery?.hasNextPage}
            next={handleFetchNextPage}
          />
        ) : null}
      </>
    )
  }

  return (
    <Card padded>
      <div className="flex flex-col gap-4">
        <DataTableMobileHeader>
          {/** TODO: Refactor Header component for mobile and desktop for both server and client side */}
          <DataTableMobileHeader.Input
            debounce={DEFAULT_SERVER_DEBOUNCE_MS}
            placeholder={t('rsp:pages.organizations.list.filterPlaceholder')}
            filteredText={searchText}
            setFilteredText={onSetSearchParams}
          />
          <RefreshOrganizationsButton />
        </DataTableMobileHeader>
        {isRequestLoading(organizationsQuery) ? <SkeletonTable /> : renderContent()}
      </div>
    </Card>
  )
}

export default OrganizationsTableMobile
