import {
  OrderStatus as ApiOrderStatus,
  OrdersQueryResult,
  ProcessOrderCommand,
  ProcessOrderDecision,
} from '@sherweb/core/openapi-generated/index.defs'

export const mapUpdateOnDataOnProcessOrder = (
  oldData?: OrdersQueryResult[],
  variables?: ProcessOrderCommand
): OrdersQueryResult[] => {
  if (!oldData) {
    return []
  }

  if (!variables) {
    return oldData
  }

  return [
    ...oldData.map(item => {
      if (variables.orderIds?.includes(item.orderId)) {
        return {
          ...item,
          orderStatus:
            variables.decision === ProcessOrderDecision.Approve
              ? ApiOrderStatus.Pending
              : ApiOrderStatus.Rejected,
          decisionNote: variables.decisionNote ?? undefined,
        }
      }
      return item
    }),
  ]
}
