import { ResellerCatalogsService } from '@sherweb/core/openapi-generated/ResellerCatalogsService'

import { DEFAULT_STALE_TIME } from '@sherweb/core/utils/const'

export const availableCatalogs = {
  queryKey: (resellerId?: string) => [
    'ResellerCatalogsService/getAvailableCatalogsByResellerId',
    resellerId,
  ],
  queryFn: async (resellerId?: string) => {
    if (!resellerId) {
      return await Promise.reject(new Error('Missing parameters'))
    }

    return await ResellerCatalogsService.getAvailableCatalogsByResellerId({ resellerId })
  },
  staleTime: DEFAULT_STALE_TIME,
}
