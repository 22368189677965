import { ResellerOrdersService } from '@sherweb/core/openapi-generated/ResellerOrdersService'

import { DEFAULT_STALE_TIME } from '@sherweb/core/utils/const'
import { rejectMissingParameters } from '@sherweb/core/utils/error'

export const availableOrdersByResellerId = {
  queryKey: (resellerId?: string) => [
    'ResellerOrdersService/getAvailableOrdersByResellerId',
    resellerId,
  ],
  queryFn: async (resellerId?: string) => {
    if (!resellerId) {
      return await rejectMissingParameters()
    }

    return await ResellerOrdersService.getAvailableOrdersByResellerId({ resellerId })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const resellerOrderDetailsById = {
  queryKey: (resellerId?: string, orderId?: string) => [
    'ResellerOrdersService/getResellerOrderDetailsById',
    resellerId,
    orderId,
  ],
  queryFn: async (resellerId?: string, orderId?: string) => {
    if (!resellerId || !orderId) {
      return await rejectMissingParameters()
    }

    return await ResellerOrdersService.getResellerOrderDetailsById({ resellerId, orderId })
  },
  staleTime: DEFAULT_STALE_TIME,
}
