import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { AmendmentOrdersService } from '@sherweb/core/openapi-generated/AmendmentOrdersService'
import {
  OrdersQueryResult,
  ProcessOrderCommand,
  ProcessOrderDecision,
} from '@sherweb/core/openapi-generated/index.defs'

import { buildOrder, buildOrders } from '@sherweb/core/modules/orders/core/orders.builder'

import { useSelectedResellerId } from '@rsp/modules/reseller'

import { mapUpdateOnDataOnProcessOrder } from './orders.builder'
import { availableOrdersByResellerId, resellerOrderDetailsById } from './orders.queries'

export const useGetOrdersQuery = () => {
  const resellerId = useSelectedResellerId()

  return useQuery({
    queryKey: availableOrdersByResellerId.queryKey(resellerId),
    queryFn: async () => await availableOrdersByResellerId.queryFn(resellerId),
    enabled: !!resellerId,
    select: data => buildOrders(data),
    staleTime: availableOrdersByResellerId.staleTime,
  })
}

export const useGetOrderQuery = (orderId?: string) => {
  const resellerId = useSelectedResellerId()

  return useQuery({
    queryKey: resellerOrderDetailsById.queryKey(resellerId, orderId),
    queryFn: async () => await resellerOrderDetailsById.queryFn(resellerId, orderId),
    select: data => buildOrder(data),
    enabled: !!resellerId && !!orderId,
    staleTime: resellerOrderDetailsById.staleTime,
  })
}

export const useProcessOrderMutation = () => {
  const queryClient = useQueryClient()

  const selectedResellerId = useSelectedResellerId()

  return useMutation({
    mutationFn: async (processOrder: ProcessOrderCommand) => {
      if (!processOrder.decision) {
        throw new Error('Missing decision')
      }

      if (processOrder?.orderIds?.length === 0) {
        throw new Error('Missing order id')
      }

      if (!processOrder.organizationId) {
        throw new Error('Missing organization id')
      }

      return await AmendmentOrdersService.amendmentOrdersProcessOrder({
        command: {
          ...processOrder,
          decision:
            processOrder.decision === ProcessOrderDecision.Approve
              ? ProcessOrderDecision.Approve
              : ProcessOrderDecision.Reject,
        },
      })
    },
    onSuccess: async (_, variables) => {
      queryClient.setQueryData<OrdersQueryResult[]>(
        availableOrdersByResellerId.queryKey(selectedResellerId),
        oldData => mapUpdateOnDataOnProcessOrder(oldData, variables)
      )
    },
  })
}
