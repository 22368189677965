import Card, { CardTitle } from '@sherweb/core/components/Card'
import { Skeleton } from '@sherweb/core/components/Skeleton'

type MarketplaceLoaderProps = {
  count?: number
}

export const MarketplaceLoader = ({ count = 2 }: MarketplaceLoaderProps) => (
  <>
    {Array.from({ length: count }, (_, index) => (
      <Card
        className="flex h-40 flex-col justify-between md:p-4"
        key={index}
        data-testid="skeletonCard"
      >
        <div className="flex flex-col">
          <CardTitle>
            <Skeleton className="mb-2 h-[16px] w-20" />
          </CardTitle>
          <Skeleton className="h-[16px] w-20" />
        </div>
        <div className="mt-4">
          <div className="flex justify-start">
            <div className="flex items-center gap-1">
              <Skeleton className="h-[16px] w-20" />
            </div>
            <div className="ml-4 flex items-center gap-1">
              <Skeleton className="h-[16px] w-20" />
            </div>
          </div>
        </div>
      </Card>
    ))}
  </>
)
