import {
  OrganizationOrderQueryResult,
  Order,
  OrderStatus,
  OrderError,
  OrdersQueryResult,
  OrderErrorResult,
  Money,
  Currency,
  OrdersLineQueryResult,
  Status,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class OrganizationOrdersService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getOrganizationOrdersById(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationOrderQueryResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/orders';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOrganizationOrderDetailsById(
    params: {
      /**  */
      organizationId: string;
      /**  */
      orderId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrdersQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/orders/{orderId}/details';
      url = url.replace('{organizationId}', params['organizationId'] + '');
      url = url.replace('{orderId}', params['orderId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}
