import { useTranslation } from 'react-i18next'

import { OrganizationOrderQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import { DataTableMobileHeader } from '@sherweb/core/components/DataTable/DataTableInputMobile'
import { useSetTableSearchParams } from '@sherweb/core/components/DataTable/hooks/useSetTableSearchParam'
import { NoResultsMobile } from '@sherweb/core/components/NoResults/NoResultsMobile'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import { OrderStatus } from '@sherweb/core/modules/orders/ui/OrderStatus'
import { OrderType } from '@sherweb/core/modules/orders/ui/OrderType'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'

import { useOrdersQuery } from '@ssp/modules/orders'

import { OrderActions } from './OrderActions'

export const OrdersTableMobile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const ordersQuery = useOrdersQuery()

  const { search: searchText, onSetSearchParams } = useSetTableSearchParams()

  const formatDate = useDateFormatter(language, dateFormatTypes.datetime)

  const filteredData = ordersQuery?.data?.filter((order: OrganizationOrderQueryResult) => {
    return (
      (order.humanReadableId ?? '').toLowerCase().includes(searchText.toLowerCase()) ||
      order.productName?.toLowerCase().includes(searchText.toLowerCase())
    )
  })

  const renderContent = () => {
    return filteredData?.length === 0 ? (
      <NoResultsMobile>{t('ssp:pages.orders.noOrder')}</NoResultsMobile>
    ) : (
      filteredData?.map(order => (
        <Accordion key={order.orderId} type="single" collapsible>
          <AccordionItem value={order.orderId}>
            <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
              {order.humanReadableId} - {order.productName}
              <AccordionTriggerSubContent>
                <OrderStatus orderStatus={order.orderStatus} />
              </AccordionTriggerSubContent>
            </AccordionTrigger>
            <AccordionContent>
              <AccordionContentList>
                <AccordionContentListItem
                  label={t('ssp:pages.orders.list.id')}
                  value={order.humanReadableId}
                />
                <AccordionContentListItem
                  label={t('ssp:pages.orders.list.productName')}
                  value={order.productName}
                />
                <AccordionContentListItem
                  label={t('ssp:pages.orders.list.createdOn')}
                  value={formatDate(order.createdOn)}
                />
                <AccordionContentListItem
                  label={t('ssp:pages.orders.list.type')}
                  value={<OrderType orderType={order?.type} />}
                />
                <AccordionContentListItem
                  label={t('ssp:pages.orders.list.quantity')}
                  value={order.requestedQuantity}
                />
                <AccordionContentListItem
                  label={t('ssp:pages.orders.list.status')}
                  value={<OrderStatus orderStatus={order.orderStatus} />}
                />
                <AccordionContentListItem
                  className="justify-end"
                  labelClassName="flex flex-wrap"
                  label={<OrderActions order={order} className="flex flex-wrap" />}
                />
              </AccordionContentList>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ))
    )
  }
  return (
    <div className="flex flex-col gap-4">
      <DataTableMobileHeader>
        <DataTableMobileHeader.Input
          placeholder={t('ssp:pages.orders.filterPlaceholder')}
          filteredText={searchText}
          setFilteredText={onSetSearchParams}
        />
      </DataTableMobileHeader>
      {isRequestLoading(ordersQuery) ? <SkeletonTable /> : renderContent()}
    </div>
  )
}

export default OrdersTableMobile
