import { Typography } from '@sherweb/core/components/Typography'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

type ShopFilterTriggerContentProps = {
  value?: string[]
  icon?: React.ReactNode
  text: string
}

export const ShopFilterTriggerContent = ({ value, icon, text }: ShopFilterTriggerContentProps) => {
  const hasValue = value?.length !== 0

  return (
    <>
      <Typography
        as="div"
        variant="body2"
        className={mergeClassName('flex items-center', {
          'text-indigo-600': hasValue,
        })}
      >
        {hasValue ? (
          <>
            {icon} {text}: {value?.join(', ')}
          </>
        ) : (
          <>
            {icon}
            <Typography variant="body2" as="div">
              {text}
            </Typography>
          </>
        )}
      </Typography>
      <div
        className={mergeClassName(
          'ml-2 mt-1 border-4 border-solid border-transparent border-t-black',
          {
            'border-t-indigo-600': hasValue,
          }
        )}
      />
    </>
  )
}
